import React from 'react'

export default function Objects() {
  return [
    <object data-v-0fe2d5ac='' key='1'>
      {/* <!--?xml version="1.0" encoding="utf-8"?--> */}
      <svg
        style={{ display: 'none' }}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <symbol viewBox='0 0 21.825 21.825' id='arrow'>
          <path d='M16.791 13.254a1.112 1.112 0 0 1 1.587 0 1.14 1.14 0 0 1 0 1.587l-6.65 6.651a1.14 1.14 0 0 1-.809.333c-.317 0-.603-.127-.81-.333l-6.65-6.651c-.444-.444-.444-1.143 0-1.587s1.143-.444 1.587 0l4.746 4.762V1.111A1.116 1.116 0 0 1 10.918 0c.619 0 1.111.492 1.111 1.111v16.904l4.762-4.761z' />
        </symbol>
        <symbol viewBox='0 0 53 53' id='clock'>
          <path d='M26.5 0C11.89 0 0 11.89 0 26.5S11.89 53 26.5 53 53 41.11 53 26.5 41.11 0 26.5 0zm0 48C14.65 48 5 38.36 5 26.5 5 14.65 14.65 5 26.5 5 38.36 5 48 14.65 48 26.5 48 38.36 38.36 48 26.5 48z' />
          <path d='M28.88 26.55v-.05c0-.75-.35-1.41-.89-1.84v-9.88c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v9.88c-.54.44-.89 1.1-.89 1.84a2.39 2.39 0 0 0 2.39 2.39c.15 0 .31-.02.45-.05l10.33 8.7c.28.24.62.35.97.35.43 0 .85-.18 1.15-.53.53-.63.45-1.58-.18-2.11l-10.33-8.7' />
        </symbol>
        <symbol viewBox='0 0 18 18' id='culture'>
          <path d='M13.1 8h2.4l-1.8-4.4H4.3L2.5 8h2.4v5.3H3.2v1h11.5v-1H13V8zm-1 5.3H9.5V8h2.6v5.3zM5 4.7h8L14 7H4l1-2.3zM5.9 8h2.6v5.3H5.9V8z' />
        </symbol>
        <symbol viewBox='0 0 24 24' id='facebook'>
          <path d='M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615V8z' />
        </symbol>
        <symbol viewBox='0 0 18 18' id='geeks'>
          <path d='M16.1 7.3H15c-.6-.8-1.5-1.3-2.5-1.3-.8 0-1.5.3-2 .8-.4-.4-.9-.7-1.5-.7s-1.1.3-1.5.8C7 6.3 6.3 6 5.5 6c-1 0-1.9.5-2.4 1.3H1.9v1h.7c0 .2-.1.4-.1.6 0 1.6 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9 0-.4-.1-.8-.2-1.1 0-.4.3-.8.8-.8s.8.3.8.8c0 .4-.1.7-.1 1.1 0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9c0-.2 0-.4-.1-.6h.7v-1zM5.5 10.8c-1.1 0-1.9-.9-1.9-1.9S4.5 7 5.5 7s1.9.9 1.9 1.9-.8 1.9-1.9 1.9zm7 0c-1.1 0-1.9-.9-1.9-1.9S11.4 7 12.5 7s1.9.9 1.9 1.9-.9 1.9-1.9 1.9z' />
        </symbol>
        <symbol viewBox='0 0 18 18' id='initiatives'>
          <path d='M6.2 14.7H6c-.2-.1-.3-.3-.3-.5v-2.4c-1.6-.5-2.8-2-2.8-3.8 0-2.2 1.8-3.9 3.9-3.9h4.3C13.3 4.1 15 5.9 15 8c0 2.2-1.8 3.9-3.9 3.9H9l-2.5 2.6c0 .1-.2.2-.3.2zm.7-9.6C5.3 5.1 4 6.4 4 8c0 1.4 1 2.6 2.4 2.9.2 0 .4.3.4.5V13l1.8-1.8c0-.2.1-.2.2-.2h2.3c1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9H6.9z' />
        </symbol>
        <symbol viewBox='0 0 24 24' id='instagram'>
          <path
            d='M21.231 0H2.769A2.77 2.77 0 0 0 0 2.769v18.46A2.77 2.77 0 0 0 2.769 24h18.463A2.769 2.769 0 0 0 24 21.229V2.769A2.769 2.769 0 0 0 21.231 0zM12 7.385a4.615 4.615 0 1 1 .002 9.23A4.615 4.615 0 0 1 12 7.385zm9 12.693a.923.923 0 0 1-.924.922H3.924A.923.923 0 0 1 3 20.078V10h1.897a7.56 7.56 0 0 0-.2.971c-.05.337-.081.679-.081 1.029a7.384 7.384 0 1 0 14.768 0c0-.35-.031-.692-.081-1.028a7.56 7.56 0 0 0-.2-.971H21v10.077zm0-13.98a.924.924 0 0 1-.924.923h-2.174a.923.923 0 0 1-.923-.923V3.923c0-.51.412-.923.923-.923h2.174c.511 0 .924.413.924.923v2.175z'
            fillRule='evenodd'
            clipRule='evenodd'
          />
        </symbol>
        <symbol viewBox='0 0 24 24' id='linkedin'>
          <path d='M4.98 3.5C4.98 4.881 3.87 6 2.5 6S.02 4.881.02 3.5C.02 2.12 1.13 1 2.5 1s2.48 1.12 2.48 2.5zM5 8H0v16h5V8zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V24H24V13.869c0-7.88-8.922-7.593-11.018-3.714V8z' />
        </symbol>
        <symbol viewBox='0 0 18 18' id='marketing'>
          <path d='M12.1 4.7h-.5L7 6.2H3.7c-1.4 0-2.5 1.1-2.5 2.5 0 1.2.9 2.2 2 2.5V13c0 .6.6 1.1 1.4 1.1S6 13.6 6 13v-1.7h1.1l4.4 1.4h.4c1.1 0 1.6-2 1.6-4 0-1.9-.4-3.8-1.4-4zM5 12.9s-.2.2-.4.2-.4-.1-.4-.1v-1.7H5v1.6zm2-2.7H3.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h3.6l3.3-1.1c-.2.7-.3 1.6-.3 2.6 0 .9.1 1.8.3 2.6l-3.3-1.1H7zm4.9 1.5c-.3-.2-.7-1.2-.7-3 0-1.7.4-2.7.6-3h.1c.2.2.6 1.2.6 3 0 1.9-.4 2.9-.6 3zm4.5-3.5H15c-.3 0-.5.2-.5.5s.2.5.5.5h1.4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zm-1.6-.4c.1 0 .2 0 .2-.1l1.1-.5c.2-.1.3-.4.2-.7-.1-.2-.4-.3-.7-.2l-1.1.5c-.2.1-.3.4-.2.7.2.2.3.3.5.3zm1.3 2.4L15 9.6c-.2-.1-.5 0-.7.2-.1.2 0 .5.2.7l1.1.5c.1 0 .1.1.2.1.2 0 .4-.1.4-.3.3-.2.2-.5-.1-.6z' />
        </symbol>
        <symbol id='newsletter' viewBox='0 0 18 18'>
          <path d='M2.1 4.7v9.1h13.7V4.7H2.1zm12.8 8.1H3.1V5.7h11.7v7.1z' />
          <path d='M9 11.6l4.7-4.2c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0l-4 3.6-4-3.7c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7L9 11.6zm3.9-1.5c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l.8.8c.1.1.2.2.4.2.1 0 .3 0 .3-.1.2-.2.2-.5 0-.7l-.8-.9zm-8.2 1.6c.1 0 .3-.1.4-.2l.8-.8c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0l-.8.8c-.2.2-.2.5 0 .7 0 .2.2.2.3.2z' />
        </symbol>
        <symbol viewBox='0 0 512.004 512.004' id='return'>
          <path d='M449.866 53l-325.592.309v41.172l325.592-.308c18.827 0 20.608 20.483 20.711 26.617v164.689c0 3.953-.58 9.202-3.376 11.652-3.645 3.211-11.37 3.376-14.332 3.109a24.436 24.436 0 0 0-3.003-.206l-379.152.308 88.914-88.376-29.286-29.109L6.074 306.374a20.476 20.476 0 0 0 0 29.109L130.342 459l29.286-29.109-88.914-88.376 377.971-.309c1.512.144 3.749.309 6.504.309 9.755 0 25.972-1.956 39.02-13.155 8.119-6.978 17.791-20.071 17.791-42.881V120.79C512 93.555 495.452 53 449.866 53z' />
        </symbol>
        <symbol viewBox='0 0 18 18' id='specimens'>
          <path d='M14.6 7.7c.3-.4.5-.9.5-1.5 0-1.3-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3 0 .6.2 1.1.5 1.5-.7.2-1.3.7-1.6 1.4l-.6-.3c.4-.5.7-1.2.7-1.9 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 .7.3 1.4.7 1.9-1.5.6-2.6 2-2.6 3.7v1.7h1.1v-1.7c0-1.5 1.2-2.7 2.7-2.8.3.2.7.3 1.2.3.4 0 .9-.1 1.2-.3 1.5.1 2.7 1.3 2.7 2.8v1.7h1.1v-1.7c0-1.2-.5-2.2-1.4-3 .3-.7 1-1.1 1.8-1.2.3.1.6.2.9.2.3 0 .6-.1.9-.2 1.1.1 2 1 2 2.1v1.2h.9v-1.2c0-1.2-.8-2.3-1.9-2.7zM4.4 6.9c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2c-1.1-.1-2-1-2-2zm6.9-.7c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5c-.9 0-1.5-.6-1.5-1.5z' />
        </symbol>
        <symbol viewBox='0 0 24 24' id='tag'>
          <path d='M9.777 2l11.394 11.395-7.78 7.777L2 9.781V2h7.777zm.828-2H0v10.609L13.391 24 24 13.396 10.605 0zM6.501 5c.4 0 .776.156 1.059.438.585.586.585 1.539.001 2.123C7.276 7.844 6.9 8 6.5 8s-.777-.156-1.06-.438a1.503 1.503 0 0 1-.001-2.123A1.495 1.495 0 0 1 6.501 5zm0-1a2.5 2.5 0 1 0 1.767 4.267A2.5 2.5 0 0 0 6.501 4z' />
        </symbol>
        <symbol viewBox='0 0 24 24' id='twitter'>
          <path d='M24 4.557a9.83 9.83 0 0 1-2.828.775 4.932 4.932 0 0 0 2.165-2.724 9.864 9.864 0 0 1-3.127 1.195 4.916 4.916 0 0 0-3.594-1.555c-3.179 0-5.515 2.966-4.797 6.045A13.978 13.978 0 0 1 1.671 3.149a4.93 4.93 0 0 0 1.523 6.574 4.903 4.903 0 0 1-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.935 4.935 0 0 1-2.224.084 4.928 4.928 0 0 0 4.6 3.419A9.9 9.9 0 0 1 0 19.54a13.94 13.94 0 0 0 7.548 2.212c9.142 0 14.307-7.721 13.995-14.646A10.025 10.025 0 0 0 24 4.557z' />
        </symbol>
      </svg>
    </object>,
    <object data-v-0fe2d5ac='' key='2'>
      {/* <!--?xml version="1.0" encoding="iso-8859-1"?--> */}
      <svg
        style={{ display: 'none' }}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <symbol id='button-border' viewBox='0 0 200 50'>
          <path
            fill='none'
            stroke='currentColor'
            strokeWidth='1'
            strokeDasharray='500 0'
            d='m2 2h196v46h-196z'
          />
          <path
            className='button__snake'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            d='m2 2h196v46h-196z'
          />
        </symbol>
        <symbol id='button-fold-a' viewBox='0 0 200 50'>
          <path stroke='none' d='M0 50 0 0 75 50Z' />
        </symbol>
        <symbol id='button-fold-b' viewBox='0 0 200 50'>
          <path stroke='none' d='M0 0 75 50 200 0Z' />
        </symbol>
        <symbol id='button-fold-c' viewBox='0 0 200 50'>
          <path stroke='none' d='M75 50 200 0 200 50Z' />
        </symbol>
      </svg>
    </object>
  ]
}
