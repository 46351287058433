import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Background from './Background'
import Navigation from './Navigation'
import { List, ListItem } from './List'
import Article from './Article'
import Objects from './Objects'

import { articles } from './content'
// import Footer from './Footer'
// import Header from './Header'
// import Menu from './Menu'
// import Objects from './Objects'

// update-active update-left update-right
// ** categories
// geeks,
// culture,
// marketing,
// specimens,
// initiatives,

// swap-leave-active swap-leave-to
// swap-enter-active swap-enter-to

const listItems = [
  [
    {
      name: 'La routine de nos spécimens',
      link: '/La routine de nos spécimens',
      category: 'specimens',
      categoryLink: '/specimens'
    },
    {
      name: 'SPK : Rétrospective 2019',
      link: '/SPK : Rétrospective 2019',
      category: 'initiatives',
      categoryLink: '/initiatives'
    },
    {
      name: 'La programmation d’automne est lancée!',
      link: '/La programmation d’automne est lancée!',
      category: 'initiatives',
      categoryLink: '/initiatives'
    },
    {
      name: 'Nicolas, attention aux clichés entrepreneuriaux',
      link: '/Nicolas, attention aux clichés entrepreneuriaux',
      category: 'culture',
      categoryLink: '/culture'
    },
    {
      name: 'Francis, spécimen SPK',
      link: '/Francis, spécimen SPK',
      category: 'specimens',
      categoryLink: '/specimens'
    },
    {
      name: 'WAQ & Web2Day',
      link: '/WAQ & Web2Day',
      category: 'marketing',
      categoryLink: '/marketing'
    }
  ],
  [
    {
      name: 'La routine de nos spécimens1',
      link: '/La routine de nos spécimens1',
      category: 'specimens',
      categoryLink: '/specimens'
    },
    {
      name: 'SPK : Rétrospective 20191',
      link: '/SPK : Rétrospective 20191',
      category: 'initiatives',
      categoryLink: '/initiatives'
    },
    {
      name: 'La programmation d’automne est lancée!1',
      link: '/La programmation d’automne est lancée!1',
      category: 'initiatives',
      categoryLink: '/initiatives'
    },
    {
      name: 'Nicolas, attention aux clichés entrepreneuriaux1',
      link: '/Nicolas, attention aux clichés entrepreneuriaux1',
      category: 'culture',
      categoryLink: '/culture'
    },
    {
      name: 'Francis, spécimen SPK1',
      link: '/Francis, spécimen SPK1',
      category: 'specimens',
      categoryLink: '/specimens'
    },
    {
      name: 'WAQ & Web2Day1',
      link: '/WAQ & Web2Day1',
      category: 'marketing',
      categoryLink: '/marketing'
    }
  ],
  [
    {
      name: 'La routine de nos spécimens2',
      link: '/La routine de nos spécimens2',
      category: 'specimens',
      categoryLink: '/specimens'
    },
    {
      name: 'SPK : Rétrospective 20192',
      link: '/SPK : Rétrospective 20192',
      category: 'initiatives',
      categoryLink: '/initiatives'
    },
    {
      name: 'La programmation d’automne est lancée!2',
      link: '/La programmation d’automne est lancée!2',
      category: 'initiatives',
      categoryLink: '/initiatives'
    },
    {
      name: 'Nicolas, attention aux clichés entrepreneuriaux2',
      link: '/Nicolas, attention aux clichés entrepreneuriaux2',
      category: 'culture',
      categoryLink: '/culture'
    },
    {
      name: 'Francis, spécimen SPK2',
      link: '/Francis, spécimen SPK2',
      category: 'specimens',
      categoryLink: '/specimens'
    },
    {
      name: 'WAQ & Web2Day2',
      link: '/WAQ & Web2Day2',
      category: 'marketing',
      categoryLink: '/marketing'
    }
  ]
]
const pagesCount = listItems.length
function App({ ...props }) {
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
    }
  }, [firstLoad])

  const id = props.match.params.id
  const url = props.match.url

  const defaultCurrentListPage = (() => {
    let page = 0
    listItems.forEach((item, index) => {
      const arr = item
      if (arr.find((el) => el.link === url)) {
        page = index
      }
    })
    return page + 1
  })()
  const articleEndLoadSpeed = 300
  const articleLoadSpeed = 400
  const listLoadSpeed = 400

  const [currentListPage, setCurrentListPage] = useState(defaultCurrentListPage)
  const [isGoingNextPage, setIsGoingNextPage] = useState(false)
  const [isGoingToPrevPage, setIsGoingToPrevPage] = useState(true)
  const [list, setList] = useState([])

  function getList(page) {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(listItems[page - 1]), 100)
    })
  }

  useEffect(() => {
    // console.log(!list || !list.length)
    // if (!list || !list.length) {
    //   setIsGoingToPrevPage(true)
    // }
    const before = new Date().getTime()
    getList(currentListPage).then((list) => {
      const after = new Date().getTime()
      const duration = after - before
      const wait = listLoadSpeed - duration
      setTimeout(() => {
        return setList(list)
      }, wait)
      // if (wait > 0) {
      //   return
      // }
      // setList(list)
    })
  }, [currentListPage])

  useEffect(() => {
    if (!list.length) {
      return
    }
    if (isGoingNextPage) {
      setIsGoingNextPage(false)
    }
    if (isGoingToPrevPage) {
      setIsGoingToPrevPage(false)
    }
    // eslint-disable-next-line
  }, [list])
  function onNextClick(e) {
    if (currentListPage >= pagesCount) {
      return
    }
    setCurrentListPage((c) => c + 1)
    setIsGoingNextPage(true)
  }
  function onPrevClick(e) {
    if (currentListPage <= 1) {
      return
    }
    setCurrentListPage((c) => c - 1)
    setIsGoingToPrevPage(true)
  }

  const [isArticleClosing, setIsArticleClosing] = useState(false)
  const [isArticleOpening, setIsArticleOpening] = useState(false)
  const [article, setArticle] = useState(null)

  function getArticle(id) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(articles.find((item) => item.title === id))
      }, 300)
    })
  }
  useEffect(() => {
    setIsArticleClosing(true)
    const before = new Date().getTime()
    getArticle(id).then((article) => {
      const after = new Date().getTime()
      const duration = after - before
      const wait = articleLoadSpeed - duration
      if (wait > 0) {
        setTimeout(() => {
          setArticle(article)
        }, wait)
        return
      }
      setArticle(article)
    })
  }, [id])

  useEffect(() => {
    if (article) {
      setIsArticleClosing(false)
      setIsArticleOpening(true)
    }
  }, [article])

  useEffect(() => {
    if (isArticleOpening) {
      setTimeout(() => setIsArticleOpening(false), articleEndLoadSpeed)
    }
  }, [isArticleOpening])

  const loading =
    isArticleOpening || isGoingToPrevPage || isGoingNextPage ? 100 : 0
  return (
    <>
      <div className={`pace${loading ? '' : ' pace-inactive'} `}>
        <div
          className='pace-progress'
          data-progress-text='100%'
          data-progress='99'
          style={{
            transform: `translate3d(${loading}%, 0px, 0px)`
          }}
        >
          <div className='pace-progress-inner' />
        </div>
        <div className='pace-activity' />
      </div>
      <Objects />
      <div className='view'>
        <main className='blog slide--inner view__content'>
          <Background />
          {/* <Menu /> */}
          <div className='blog__content blog__content--filters'>
            <section className='post'>
              <aside className='list post__list'>
                <List
                  isGoingToNext={isGoingNextPage}
                  isGoingToPrev={isGoingToPrevPage}
                >
                  {list.map((item, index) => (
                    <ListItem
                      key={index + 1}
                      {...item}
                      isActive={item.link === url}
                    />
                  ))}
                </List>
                <Navigation
                  currentPage={currentListPage}
                  pagesCount={pagesCount}
                  onNextClick={onNextClick}
                  onPrevClick={onPrevClick}
                  firstLoad={firstLoad}
                />
              </aside>
              <div className='post__scrollbox'>
                <Article
                  article={article}
                  isArticleClosing={isArticleClosing}
                  isArticleOpening={isArticleOpening}
                />
              </div>
            </section>
          </div>
          {/* <Header />
          <Footer /> */}
        </main>
      </div>
    </>
  )
}

App.propTypes = {
  match: PropTypes.object
}

export default App
