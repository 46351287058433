import React, { useState, useEffect } from 'react'

export default function Background() {
  const [firsLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firsLoad) {
      setFirstLoad(false)
    }
  }, [firsLoad])

  return (
    <div className='background'>
      <svg
        viewBox='-700,-200,1750,1250'
        className={`vector blog__vector vector--static${
          firsLoad ? ' blog_vector_hide' : ' blog__vector_enter'
        }`}
      >
        <g
          fill='#0f2f59'
          stroke='none'
          opacity='0.15'
          transform='rotate(-40 750 500) '
        >
          <path opacity='0.3' d='M500,950,150,860,180,450z' />
          <path opacity='0.2' d='M500,950,180,450,390,480z' />
          <path opacity='0.1' d='M180,450,390,480,240,350z' />
          <path opacity='0.1' d='M500,950,390,480,550,350z' />
          <path opacity='0.2' d='M390,480,550,350,400,270z' />
          <path opacity='0.15' d='M500,950,550,350,1180,600z' />
          <path opacity='0.3' d='M500,950,1180,600,1030,810z' />
          <path opacity='0.2' d='M550,350,1180,600,775,225z' />
          <path opacity='0.25' d='M550,350,775,225,650,200z' />
          <path opacity='0.15' d='M550,350,650,200,400,270z' />
          <path opacity='0.1' d='M775,225,1180,600,1090,50z' />
          <path opacity='0.3' d='M1180,600,1090,50,1350,480z' />
        </g>
        <g />
      </svg>
      <h3
        className={`background__banner blog__banner${
          firsLoad ? ' blog__banner_hide' : ' blog__banner_enter'
        }`}
      >
        Blogue
      </h3>
    </div>
  )
}
