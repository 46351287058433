export const articles = [
  {
    title: 'La routine de nos spécimens',
    category: 'specimens',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/b94f114c-03b5-425d-a13d-b4d5526dfa4d_HfZ5jE_g.jpeg?auto=compress,format&rect=0,458,5472,2736&w=800&h=400',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'SPK : Rétrospective 2019',
    category: 'initiatives',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/9aeee679-bc73-4ca6-83f0-7b9f83cd0a08_retro2019%402x.png?auto=compress,format&rect=0,0,1600,800&w=800&h=400',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'La programmation d’automne est lancée!',
    category: 'initiatives',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/a4eba1abdb82029901442fcaf035e221baef85b9_spk_newsletter_template_header_events2x-1.png?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'Nicolas, attention aux clichés entrepreneuriaux',
    category: 'culture',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/eed45855107043da6119ec54fe14d9338a07c2b4_afterlight-4-copy.jpg-2.jpeg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'Francis, spécimen SPK',
    category: 'specimens',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/224ab19b634eda884613cd0b8c9dc6d3d781346f_spk_web_portraitscolor_eliasdjemil_013.jpg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'WAQ & Web2Day',
    category: 'marketing',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/6a4c3ee8b77b31d3109626bbdc7ff6850dc9d186_nantes1.jpg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'La routine de nos spécimens1',
    category: 'specimens',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/b94f114c-03b5-425d-a13d-b4d5526dfa4d_HfZ5jE_g.jpeg?auto=compress,format&rect=0,458,5472,2736&w=800&h=400',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'SPK : Rétrospective 20191',
    category: 'initiatives',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/9aeee679-bc73-4ca6-83f0-7b9f83cd0a08_retro2019%402x.png?auto=compress,format&rect=0,0,1600,800&w=800&h=400',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'La programmation d’automne est lancée!1',
    category: 'initiatives',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/a4eba1abdb82029901442fcaf035e221baef85b9_spk_newsletter_template_header_events2x-1.png?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'Nicolas, attention aux clichés entrepreneuriaux1',
    category: 'culture',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/eed45855107043da6119ec54fe14d9338a07c2b4_afterlight-4-copy.jpg-2.jpeg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'Francis, spécimen SPK1',
    category: 'specimens',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/224ab19b634eda884613cd0b8c9dc6d3d781346f_spk_web_portraitscolor_eliasdjemil_013.jpg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'WAQ & Web2Day1',
    category: 'marketing',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/6a4c3ee8b77b31d3109626bbdc7ff6850dc9d186_nantes1.jpg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'La routine de nos spécimens2',
    category: 'specimens',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/b94f114c-03b5-425d-a13d-b4d5526dfa4d_HfZ5jE_g.jpeg?auto=compress,format&rect=0,458,5472,2736&w=800&h=400',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'SPK : Rétrospective 20192',
    category: 'initiatives',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/9aeee679-bc73-4ca6-83f0-7b9f83cd0a08_retro2019%402x.png?auto=compress,format&rect=0,0,1600,800&w=800&h=400',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'La programmation d’automne est lancée!2',
    category: 'initiatives',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/a4eba1abdb82029901442fcaf035e221baef85b9_spk_newsletter_template_header_events2x-1.png?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'Nicolas, attention aux clichés entrepreneuriaux2',
    category: 'culture',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/eed45855107043da6119ec54fe14d9338a07c2b4_afterlight-4-copy.jpg-2.jpeg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'Francis, spécimen SPK2',
    category: 'specimens',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/224ab19b634eda884613cd0b8c9dc6d3d781346f_spk_web_portraitscolor_eliasdjemil_013.jpg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  },
  {
    title: 'WAQ & Web2Day2',
    category: 'marketing',
    date: '8 AVRIL 2020',
    image:
      'https://images.prismic.io/spk-blog/6a4c3ee8b77b31d3109626bbdc7ff6850dc9d186_nantes1.jpg?auto=compress,format',
    description:
      'Deux grosses semaines pour les passionnés de technologies comme nous. Premièrement, le web à Québec, puis le web2day, à Nantes, à laquelle nous avons eu la chance d’assister dans le cadre du parcours numérique francophone. Deux évènements avec différentes conférences qui ont nourri notre soif d’en connaître toujours un peu plus.'
  }
]
