import React from 'react'
import PropTypes from 'prop-types'

function Navigation({
  currentPage,
  pagesCount,
  onPrevClick,
  onNextClick,
  firstLoad,
  ...props
}) {
  // const [firstLoad, setLoad] = React.useState(true)
  // React.useEffect(() => {
  //   setLoad(false)
  // }, [firstLoad])
  return (
    <nav
      className={`list__nav${firstLoad ? ' list_nav_hide' : ' list_nav_enter'}`}
    >
      <button
        className='paging paging--prev'
        data-disabled={currentPage === 1}
        onClick={onPrevClick}
      >
        <span className='hidden'>previous</span>
      </button>{' '}
      <button
        className='paging'
        data-disabled={currentPage === pagesCount}
        onClick={onNextClick}
      >
        <span className='hidden'>next</span>
      </button>
    </nav>
  )
}

Navigation.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  firstLoad: PropTypes.bool
}

export default Navigation
