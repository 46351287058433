import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export function ListItem({
  name,
  link,
  category,
  categoryLink,
  isActive,
  onClick
}) {
  function _onClick(e) {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }
  return (
    <li className='list__item'>
      <Link
        to={link}
        className={`list__post${isActive ? ' active' : ''}`}
        onClick={() => _onClick(link)}
      >
        <div className='list__content'>
          <h5 className='list__title'>{name}</h5>
        </div>
      </Link>
      <a href={categoryLink} className=''>
        <span className='hidden'>{category}</span>
        <svg className={`icon list__icon list__icon--${category}`}>
          <use xlinkHref={`#${category}`} />
        </svg>
      </a>
    </li>
  )
}
ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  category: PropTypes.oneOf([
    'geeks',
    'culture',
    'marketing',
    'specimens',
    'initiatives'
  ]).isRequired,
  categoryLink: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}

export function List({ isGoingToNext, isGoingToPrev, ...props }) {
  const children = props.children

  return (
    <ul
      className={`list__group${
        isGoingToNext ? ' update-active update-left' : ''
      }${isGoingToPrev ? ' update-active update-right' : ''}`}
    >
      {children}
    </ul>
  )
}
List.propTypes = {
  isGoingToNext: PropTypes.bool,
  isGoingToPrev: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string
}
