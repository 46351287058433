import React from 'react'
import PropTypes from 'prop-types'

function Article({ article, isArticleClosing, isArticleOpening, ...props }) {
  if (!article) {
    return (
      <article
        className={`post__content${
          isArticleClosing ? ' swap-leave-active swap-leave-to' : ''
        }${isArticleOpening ? ' swap-enter-active swap-enter-to' : ''}`}
      />
    )
  }
  return (
    <article
      className={`post__content${
        isArticleClosing ? ' swap-leave-active swap-leave-to' : ''
      }${isArticleOpening ? ' swap-enter-active swap-enter-to' : ''}`}
    >
      <h1 className='post__title'>{article.title}</h1>
      <time className='post__published'>{article.date}</time>
      <figure className='post__feature'>
        <img src={article.image} className='post__image' alt={article.title} />
        <a href='/blogue/categorie/specimens' className=''>
          <span className='hidden'>{article.category}</span>
          <svg
            className={`icon post__category post__category--${article.category}`}
          >
            <use xlinkHref={`#${article.category}`} />
          </svg>
        </a>
      </figure>
      <div className='post__body rte'>
        <p>{article.description}</p>
      </div>
    </article>
  )
}

Article.propTypes = {
  match: PropTypes.object,
  article: PropTypes.object,
  isArticleClosing: PropTypes.bool,
  isArticleOpening: PropTypes.bool
}

export default Article
